/* GLOBAL */
h1,h2,h3,h4,h5,p {
    word-break: break-word;
}

/* GLOBAL */
.main-container .region.region-content {
    .headline-block {
        font-size: 50px;
        font-weight: 100;
        text-align: center;
        text-transform: uppercase;
        margin-top: 0px;
        @media (max-width: @screen-sm-max) {
            font-size: 40px;
        }
    }
    .headline-freestanding {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 0;
        @media (max-width: @screen-sm-max) {
            padding-top: 50px;
        }
    }
    .view-header {
        .headline-block.headline-freestanding {
            margin-bottom: -20px;
        }
    }
}

/* admin menu */
#admin-menu-wrapper {
    padding: 6px 10px 0;
}
.btn {
    white-space: inherit;
}
ol
    /* media container width settings */
#navbar > .container {
    @media (min-width: @screen-xs-min) {
    }
    @media (min-width: @screen-sm-min) {
        max-width: @container-sm;
    }
    @media (min-width: @screen-md-min) {
        max-width: @container-md;
    }
    @media (min-width: @screen-lg-min) {
        max-width: @container-lg;
    }
}
/*partner*/
.view.view-partner{
    .view-content {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        .views-row{
            border-bottom: 1px solid #eeeeee;
        }
        .views-field{
            h1{
                font-size: 32px;
                text-align: unset;
                padding-left: 10px;
                margin: 10px;
                font-weight: 500;
            }
            img{
                max-width: 70%;
                //   margin-top: -20px;
                // margin-bottom: -10px;
            }
            .field-content{
                padding: 10px 10px 20px 10px;
            }
        }
    }
}

/* header */
#navbar {
    margin-bottom: 0px;
    padding-top: 5px;
    width: 100%;
    margin: 0px;
    max-width: 100%;
    z-index: 100;

    .navbar-header {
        /*transition: all @headerTransitionSpeed ease;*/
        max-width: 420px;

        .logged-in & {
            max-width: 300px;
            margin-top: 10px;
        }

        @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
            max-width: 300px;
            margin-top: 10px;
        }

        a {
            width: 100%;
            padding-right: 0px;
        }

        img {
            max-width: 100%;
            @media (max-width: @screen-sm-max) {
                padding-right: 100px;
            }
        }
    }

    & > .container {
        margin: 0px auto;
        position: relative;
        @media (min-width: @screen-md) {
            transform: translateY(20%);
        }

        padding-right: 0px;
        @media (min-width: @screen-lg) {
            padding-right: 15px;
        }
    }

    &.navbar.container {
        /*transition: all @headerTransitionSpeed ease;*/
        margin-top: 0px;
        min-height: 75px;
        @media (min-width: @screen-md-min) {
            min-height: 130px;
        }
        background-color: @brand-red;
    }

    &.navbar-default,
    .navbar-collapse {
        border: none;
        box-shadow: none;
    }

    button.navbar-toggle {
        /*margin-top: 10px;*/
        position: absolute;
        right: 15px;
        border: 0px;
        background-color: transparent;

        .icon-bar {
            width: 30px;
            height: 4px;
            border-radius: 6px;
            margin-bottom: 5px;
            background-color: @white;

        }
    }

    ul.menu {
        /*transition: all @headerTransitionSpeed ease;*/
        /*padding-top: 20px;*/
        color: @white;

    }

    .navbar-collapse {
        /*float: right;*/
        margin: 0 auto;
        @media (min-width: @screen-md) {
            float: right;
        }

        .logged-in & li a {
            padding: 10px 6px;
        }

        @media (max-width: @screen-lg-min) {
            li a {
                padding: 10px 10px;
                font-size: 12px;
            }
        }
        /* for open burger menu */
        @media (max-width: @screen-sm-max) {
            width: 100%;
            text-align: center;
            /*margin-bottom: 30px;*/
            ul {
                text-align: center;

                &.dropdown-menu {
                    background-color: @brand-light-grey;

                    li a {
                        color: @brand-red;
                    }
                }
            }

            li a {
                padding: 10px 15px;
                font-size: 16px;
            }

        }
    }

    /* login form (overlay) */

    #block-user-login {
        #login-form-close {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 18px;
            cursor: pointer;
        }

        display: none;
        position: absolute;
        top: 68px;
        right: 30px;
        border: 1px solid #ffffff;
        padding: 10px;
        padding-top: 20px;
        background-color: @brand-red;
        color: @white;
        z-index: 1;

        label {
            color: @white;
        }

        ul {
            list-style: none;
            margin: 10px;
            margin-left: -40px;
        }

        a {
            color: @white;
        }

        a:hover {
            text-decoration: underline;
        }

        button {
            background-color: @brand-turquoise;
            color: @white;
            margin-top: 10px;
            /*float: right;*/
            width: 100%;
        }
    }
}

/* form elements */
button[type="submit"] {
    margin-top: 15px;
    margin-bottom: 15px;

    #user-login & {
        margin-top: 0px;
    }
}

.main-container {
    .page-header {
        border-bottom: none;
    }

    h1 {
        font-size: 50px;
        color: @black;
        margin-top: 80px;
        margin-bottom: 40px;
        text-align: center;
        /* für alle Suchen */
        text-transform: uppercase;
        &.page-header {
            @media (max-width: @screen-sm-max) {
                letter-spacing: -3px;
            }
            @media (max-width: @screen-xs) {
                font-size: 40px;
                letter-spacing: -5px;
            }
            &.sub-position {
                margin: 20px 0;
                padding: 0;
            }
        }
    }

    /* form elements views */

    .view-header {
        margin-bottom: 20px;
        margin-left: auto;

        a {
            /*float:right;*/
            text-transform: uppercase;
            background-color: @brand-turquoise;
            color: @white;
            padding: 4px 8px;
        }
    }

    .views-exposed-form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        margin-bottom: 65px;
        border-bottom: 1px solid @brand-red;

        .views-exposed-widget {
            padding-right: 3px;

            .btn {
                margin-top: 0px;
                text-transform: uppercase;
            }
        }
    }

    .node-landing-page {
        h1 {
            @media(max-width: @screen-sm-max) {
                font-size: 40px;
                letter-spacing: -4px;
            }
        }
        h2 {
            @media(max-width: @screen-sm-max) {
                letter-spacing: -2px;
            }
        }
        .landing-page-banner {
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 200px;
            @media (min-width: @screen-md-min) {
                height: 300px;
            }
            @media (min-width: @screen-lg-min) {
                height: 400px;
            }
        }
        .main-content {
            .field-name-body {
                margin-bottom: 40px;
            }
            .field-type-blockreference {
                section {
                    margin: 0;
                    .views-exposed-form {
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    .view-music-teaching .view-empty {
        text-align: center;
        padding-bottom: 30px;
    }

    /* search items */

    .views-bootstrap-thumbnail-plugin-style {
        .thumbnail {
            padding: 0px;
            border: none;
            background-color: @brand-light-grey;

            h3,
            h3 a {
                color: @brand-red;
                word-wrap: break-word;
                margin-bottom: 30px;
            }

            a img {
                margin: 0px;
                width: 100%;
            }

            .caption {
                padding: 0px 25px 15px;
            }

            .fieldlabel,
            .fieldcontent,
            .description,
            .content-type {
                color: @black;
            }

            .fieldcontent,
            .description,
            .content-type {
                margin-bottom: 15px;
            }
            .content-type {
                text-transform: capitalize;
            }

            .fieldlabel {
                text-transform: uppercase;
                font-weight: 700;
            }

            .description {
                font-style: italic;
                &:before {
                    content: "„";
                }
                &:after {
                    content: " “";
                }
            }

            .entfernung .fieldcontent:after {
                content: " ";
                display: inline-block;
                width: 11px;
                height: 14px;
                margin-left: 10px;
                background-image: url(../images/layout/map_marker.png);
            }

            .caption > div:last-child:after {
                content: "Mehr Informationen";
                display: block;
                background-color: @brand-dark-grey;
                text-transform: uppercase;
                text-align: center;
                color: @white;
                margin-top: 20px;
                padding: 4px 8px;
                font-weight: 200;
            }
        }

        .thumbnail:hover {
            background-color: @brand-red;
            cursor: pointer;

            h3,
            h3 a {
                color: @white;
            }

            .fieldlabel,
            .fieldcontent,
            .description,
            .content-type {
                color: @white;
            }

            .entfernung .fieldcontent:after {
                background-image: url(../images/layout/map_marker_white.png);
            }

            .caption > div:last-child:after {
                background-color: @brand-turquoise;
            }
        }
    }
}

/* footers */
#outer-footer-section .footer-section .section-divider-footer {
    /*margin-top: 30px;*/

    /* The image used */
    background-image: url("../images/parallax/parallax-section-spacer-image.jpg");
    @media (min-width: 650px) {
        background-image: url("../images/parallax/parallax-section-spacer-image-sp-mit-logo.jpg");
    }
    .musikunterricht & {
        background-image: url("../images/parallax/parallax-section-spacer-image.jpg");
    }

    /* Set a specific height */
    height: 400px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
/*@media only screen and (max-device-width: 1366px) {
  #outer-footer-section .footer-section .section-divider-footer {
    background-attachment: scroll;
  }
}*/

/* footer links */
#outer-footer-links .linklist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: @screen-md) {
        justify-content: start;
    }
    padding-top: 30px;
    padding-bottom: 45px;

    h4 {
        color: @black;
        margin-top: 30px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .col:not(:last-child) {
        padding-right: 30px;
    }

    .link {
        padding-top: 5px;
    }

    a {
        color: @brand-dark-grey;
        text-transform: uppercase;
    }

    a:hover {
        color: @brand-red;
    }
}


/* footer contact */
#outer-footer-contact {
    background-color: #ebebeb;
    color: @brand-dark-grey;
    padding: 30px 0;

    h2, h3 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 10px;
    }

    /* contact-data */
    #block-block-10 {
        width: 100%;
        margin-bottom: 80px;
        @media (min-width: @screen-sm) {
            width: 40%;
        }
        @media (min-width: @screen-md) {
            width: 30%;
        }
        h3 {
            font-size: 19px;
            margin-top: 10.5px;
            margin-bottom: 10.5px;
            font-weight: 500;
            line-height: 1.1;
        }
        a {
            color: @brand-dark-grey;
        }

        a:hover {
            color: @brand-red;
        }

        ul.social-links {
            margin-left: -45px;
            li {
                padding-top: 20px;
                float: left;
                margin: 5px !important;
                list-style: none;
            }
            .icon {
                color: #FFF;
                font-size: 24px;
                padding: 8px 8px;
            }
            a {
                text-decoration: none;
            }
            .service-facebook .icon {
                background-color: #3b5998;
                padding-left: 8px;
                &:before {
                    padding-left: 8px;
                }
            }
            .service-instagram .icon {
                background-color: #3f729b;
                /*&:before {
                    padding-right: 28px;
                }*/
            }
            .service-twitter .icon {
                background-color: #55acee;
                padding-right: 6px;
                padding-left: 6px;
                &:before {
                    padding-right: 28px;
                }
            }
        }
    }

    #block-formblock-contact-site {
        width: 100%;
        @media (min-width: @screen-sm) {
            width: 60%;
        }
        @media (min-width: @screen-md) {
            width: 70%;
        }

        button {
            text-transform: uppercase;
            background-color: @brand-turquoise;
            color: @white;
            border-color: @brand-light-grey;

            &:hover {
                background-color: #006e78;
                border-color: #004d54;
            }
        }
    }

    .region-footer-contact {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    form.contact-form {
        @media (min-width: @screen-md) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .first-col {
            width: 100%;
            @media (min-width: @screen-md) {
                width: 45%;
            }
        }

        .second-col {
            width: 100%;
            @media (min-width: @screen-md) {
                width: 45%;
            }
            margin-top: 40px;
        }
    }
}

#outer-footer {
    background-color: @brand-red;

    .footer {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: none;
    }

    #block-menu-menu-footer-menu {
        float: left;

        ul.menu.nav {
            position: relative;
            text-transform: uppercase;

            li {
                float: left;
            }

            li:not(:first-child):after {
                content: "|";
                display: block;
                color: @white;
                position: absolute;
                top: 8px;
            }

            a {
                color: @white;
                margin-left: 10px;
                padding: 10px 5px;
                font-size: 13px;
            }

            a:hover {
                color: @brand-red;
            }
        }
    }

    /* copyright block */

    #block-block-9 {
        float: right;

        .copyright {
            padding-top: 8px;
            color: @white;
            font-size: 13px;
            text-transform: uppercase;
        }
    }
}

/* slick carousel */
.slick--display--thumbnail.slick--skin--asnavfor .slick__slide.slick-current img,
.slick--display--thumbnail.slick--skin--asnavfor .slick__slide img {
    border: 4px solid @brand-red;
}

.slick--display--thumbnail.slick--skin--asnavfor .slick__slide.slick-current::before {
    border-bottom-color: @brand-red;
    border-width: 16px;
    margin-left: -16px;
    top: -16px;
}

.slick--display--thumbnail.slick--skin--asnavfor.slick--center .slick-track {
    padding-top: 35px;
}

.slick-wrapper {
    margin-bottom: 0px;
}

/* line divider in a content row*/
.line-divider {
    border-bottom: 1px solid @brand-red;
    margin: 0px 0px 15px 0px;
    .node-type-article &,
    .group-row-image & {
        margin-top: 15px;
    }
}

/* user profile */
.page-user {
    h1 {
        text-align: left;
        margin: 15px 0px;
        font-weight: 500;
        color: @brand-middle-grey;
    }

    .profile {
        .field-name-realname {
            border-bottom: 1px solid @brand-red;
            font-size: 25px;
            padding-bottom: 5px;
            margin-bottom: 20px;

            .field-label {
                display: none;
            }
        }

        .field-name-field-description {
            margin-bottom: 25px;
        }

        .field-name-field-fc-instrument {

        }


        .field-label-above {
            margin-bottom: 25px;

            .field-label {
                font-size: 15px;
            }
        }

        .field-name-field-fc-instrument .field-item {
            &:not(:last-child) {
                .field-name-field-instrument .field-item::after {
                    content: ",";
                    margin-right: 6px;
                    display: inline-block;
                }

                float: left;
            }
        }

        .field-name-field-fc-music-album-title,
        .field-name-field-fc-music-title {
            .field-item {
                .field {
                    &.field-name-field-music-artist {
                        color: @brand-red;
                        font-weight: 600;

                        .field-item:after {
                            content: "-";
                            margin-right: 6px;
                            margin-left: 6px;
                            display: inline-block;
                        }
                    }

                    float: left;
                }
            }

            & > .field-items > .field-item:not(:last-child) {
                .field-name-field-music-album-title,
                .field-name-field-music-title {
                    .field-item:after {
                        content: "|";
                        margin-right: 10px;
                        margin-left: 10px;
                        display: inline-block;
                    }
                }

                float: left;
            }
        }

        .field-name-field-favorite-music-genres,
        .field-name-field-favorite-music-artists {
            .field-item:not(:last-child) {
                &:after {
                    content: ",";
                    margin-right: 6px;
                    display: inline-block;
                }
            }

            .field-item {
                display: inline-block;
            }
        }
    }

    .flag-outer-friend {
        margin-top: 20px;
    }

    /*.social-links ul limargin: 5px 10px 5px 0px !important*/

    .field-name-field-social-links {
        .field-label {
            display: none;
        }

        &:not(.social-links) > .field-items {
            padding-top: 10px;
            margin-top: 30px;
            border-top: 1px solid @brand-red;
        }

        .social-links ul {
            li {
                margin: 5px 10px 5px 0px !important;
            }

            overflow: inherit;
        }
    }
}

/* profile detail head */
.node-type-musician-profile,
.node-type-anzeige-proberaum,
.node-type-ad-job,
.node-type-ad-teacher,
.node-type-anzeige-musikschule,
.node-type-article,
.node-type-ad-marketplace,
.node-type-event-calendar,
.group-page.bandprofil {
    .submitted .author {
        display: none;
    }
    h1 {
        padding-left: 15px;
    }
    h1,
    h2,
    div.type.subtitle {
        word-wrap: break-word;
        color: @brand-middle-grey;
        margin: 50px 0px 10px 0px;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        span {
            .divider {
                padding: 0px 10px;
            }

            position: relative;
            font-weight: 300;
            text-transform: none;
        }
    }
    p {
        font-size: 15px;
    }
    .field-name-body {
        h2, h3 {
            font-size: @font-size-h3;
            color: @brand-red;
            font-weight: 500;
            margin: 25px 0px 10px;
        }
        h3 {
            font-weight: 300;
            margin-top: 20px;
        }
    }
    .slick--display--thumbnail .slick-track {
        margin-top: 32px;
        margin-bottom: 12px;
    }
    .related-articles {
        h2 {
            margin-top : 10px;
        }
        h3 {
            margin    : 7px 0px 3px 0px;
            font-size : 16px;
        }
        td {
            border-color: @brand-red;
            padding: 30px 0px 25px;
        }
        p {
            color: @brand-middle-grey;
        }
    }

    .like {
        margin-top: 55px;
        float: right;
        /*@media (max-width: @screen-xs-max) {
            position: absolute;
            top: -3rem;
            right: 0;
            margin-top: 0;
            width: 120px;
        }*/
        @media (max-width: @screen-md-max) {
            margin-top: 25px;
        }

        a {
            color: @brand-turquoise;

            &:hover {
                color: @brand-red;
            }
        }
    }

    .copyright {
        text-align: right;
        padding-bottom: 10px;
    }

    .title {
        h1 {
            font-weight: 600;
            font-size: 26px;
            padding-left: 0px;
            text-transform: none;
            margin-top: 0px;
            @media (min-width: @screen-xs) {
                font-size: 30px;
            }
            @media(min-width:   @screen-sm){
                font-size: 40px;
            }
        }
    }

    .main-content .field-item {
        color: @brand-middle-grey;
    }

    .field-label {
        margin-top: 15px;
    }

    .field_nutzflaeche .field-item::after {
        content: "m²";
        margin-left: 6px;
        display: inline-block;
    }

    .field-name-field-fc-room-equipment .field-item,
    .field-name-field-fc-instrument .field-item,
    .field-name-field-fc-bandtags .field-item {
        &:not(:last-child) {
            .field-name-field-room-equipment .field-item::after,
            .field-name-field-instrument .field-item::after,
            .field-name-field-bandtag .field-item::after {
                content: ",";
                margin-right: 6px;
                display: inline-block;
            }
        }

        float: left;
    }

    .field-name-field-favorite-music-genres .field-item,
    .field-name-field-tags .field-item {
        &:not(:last-child) {
            &:after {
                content: ",";
                margin-right: 6px;
                display: inline-block;
            }
        }

        float: left;
    }

    /*********************
        .field-name-field-bandmitglied,
        .field-name-field-bandmitgliedsname {
            diesplay: none;
            &:not(:last-child) {
                a:after {
                    content: ":";
                    margin-right: 6px;
                    display: inline-block;
                }
            }
            float: left;
        }
    ************************/
    .review-box {
        background: @brand-red;
        padding: 25px 10px 100px;
        color: #ffffff;
        position: relative;
        margin-bottom: 30px;
        min-height: 60px;

        h3 {
            padding: 0px 15px 20px;
        }
        .views-label,
        .field-content {
            display: block;
            width:100%;
            font-size: 16px;
            font-weight: 700;
            padding-left: 15px;
            padding-right: 15px;
        }
        .field-content {
            padding-top: 5px;
            padding-bottom: 10px;
        }

        .buttons {
            padding: 0px 5px;
            text-align: center;
            a {
                padding-left: 7px;
                padding-right: 7px;
            }
        }

        .views-field-field-average {
            padding-bottom: 40px;
            border-bottom: 1px solid #D3D7D9;
        }
    }
    .review-box-empty {
        margin-bottom: 25px;
        .btn.btn-default {
            width: 100%;
        }
    }

    .contact-box {
        background: @brand-red;
        padding: 0px 10px;
        color: #ffffff;
        position: relative;
        /*margin-top: 30px;*/
        margin-bottom: 30px;

        a {
            &:hover {
                color: @brand-light-grey;
            }

            color: @white;
            font-weight: 500;
        }

        h4,
        .field-label {
            font-size: 16px;
            font-weight: 700;

            &.phoneHeadline {
                position: relative;
                top: 20px;
                margin-left: 3px;
                margin-top: 0px;
            }
        }

        .field-name-field-contact-phone,
        .field-name-field-contact-fax,
        .field-name-field-contact-mobile {
            .field-label {
                margin-top: -2px;
                font-weight: 300;
                margin-right: 15px;
            }
        }

        .private_message {
            text-align: center;

            a {
                width: 96%;
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }
    }

    .group-contact-box {
        .field-name-field-fc-contact .field-label {
            margin-top: 0px;
        }
        .content {
            padding: 15px;
        //    .field-label {
        //        padding-top: 15px;
        //    }
            .field-name-field-contact-mobile .field-label {
                padding-top: 0px;
            }
        }
        .field_social_links {
            border-top: 1px solid #ffffff;
            padding-top: 1em;
        }
    }

    .field-name-field-fc-contact {
        &:after {
            .contactBubble();
        }

        h3 {
            margin: 75px 0px 40px 10px;
            &.group-contact-title {
                margin: 0px;
                padding: 75px 0px 40px 10px;
            }
            font-size: 30px;
        }
    }

    .user {
        margin: 0px 10px;

        .user-picture {
            img {
                max-width: 50%;
                float: right;
            }
        }
    }

    .field_social_links {
        border-bottom: 1px solid #D3D7D9;
        margin-bottom: 1em;

        .field-name-field-social-links ul.field-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            /*justify-content: space-between;*/
            justify-content: flex-start;
            padding-bottom: 1px !important;
            margin-top: 0px;
            margin-bottom: 0px;

            li {
                padding-bottom: 15px !important;
            }
        }
    }

    .row-address {
        margin-top: 60px;

        .title {
            border-bottom: 1px solid @brand-red;
        }

        .field-name-field-addressfield {
            padding: 30px 0px 0px 0px;
            @media (max-width: @screen-xs-max) {
                padding-top: 0px;
                padding-bottom: 15px;
            }
            @media (min-width: @screen-sm-min) {
                .make-sm-column(4);
            }
            @media (min-width: @screen-md-min) {
                .make-md-column(3);
            }
            @media (min-width: @screen-lg-min) {
                .make-lg-column(3);
            }
        }

        .field-name-field-geofield {
            padding: 0px 0px 0px 0px;
            padding-right: 0px !important;
            @media (min-width: @screen-sm-min) {
                .make-sm-column(8);
            }
            @media (min-width: @screen-md-min) {
                .make-md-column(9);
            }
            @media (min-width: @screen-lg-min) {
                .make-lg-column(9);
            }
        }

        .address-items {
            margin: 0px;
            color: @brand-grey;
        }

        .entity-field-collection-item {
            padding-top: 10px;
            padding-bottom: 25px;
            @media (min-width: @screen-lg-min) {
                padding-top: 20px;
                padding-bottom: 35px;
            }
            @media (min-width: @screen-md-min) {
                padding-top: 15px;
                padding-bottom: 30px;
            }
            border-bottom: 1px solid @brand-red;
        }

        .field-collection-container .field-items .field-item {
            margin-bottom: 0px;
        }

        .field-collection-container,
        .field-collection-view {
            border: none;
            margin: 0px;
        }
    }

    .row-soundcloud {
        margin-top: 60px;

        .title {
            border-bottom: 1px solid @brand-red;
        }

        .content-col {
            padding-right: 15px;
        }

        .field_soundcloud {
            margin-top: 30px;

            .field-item {
                padding-bottom: 25px;

                &:not(:last-child) {
                    border-bottom: 1px solid @brand-red;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .row-sharing {
        .social_outer_wrapper {
            text-align: center;
        }

        .title {
            display: inline-block;
        }

        .social_items ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .title h3 {
            text-transform: uppercase;
            margin-top: 35px;
            margin-bottom: 25px;
            font-size: 30px;
            color: @brand-middle-grey;
            @media (min-width: @screen-xs) {
                font-size : 45px;
            }
            @media (min-width: @screen-sm) {
                font-size : 55px;
            }
        }

        .shariff li {
            height: 80px;
            min-width: 85px;
            max-width: 85px;

            a {
                text-align: left;
                height: 80px;
                width: 80px;
                border-radius: 100%;

                .fa {
                    font-size: 55px;
                    padding-top: 25px;
                    padding-left: 15px;

                    &.fa-facebook {
                        padding-left: 20px;
                    }
                }

                .share_text {
                    display: none;
                }
            }
        }
    }

    footer {
        position: relative;
        margin-top: 80px;
        position: relative;

        ul li.comment-add {
            position: absolute;
            top: 6px;
            right: 0px;

            a {
                color: @brand-turquoise;

                &:hover {
                    color: @brand-red;
                }
            }
        }
    }

    #comments {
        margin-bottom: 60px;

        h2 {
            border-bottom: 1px solid @brand-red;
            font-weight: 300;
            font-size: 24px;
            margin-top: 30px;
            margin-bottom: 20px;
            padding-bottom: 10px;

            &:first-child {
                margin-top: 10px;
            }

            /*
                        &.comment-form {
                            border-top: 1px solid @brand-red;
                            margin-top: 0px;
                            padding-top:80px;
                        }
            */
        }

        div.comment {
            margin-left: 40px;
            margin-top: 15px;
            margin-bottom: 30px;
            border-bottom: 1px solid @brand-red;

            &:last-of-type {
                border: none;
                margin-bottom: 80px;

                &:after {
                    content: "";
                    display: block;
                    border-bottom: 1px solid @brand-red;
                    margin-left: -40px;
                    visibility: visible;;
                    @media (min-width: @screen-sm-min) {
                        width: (@container-sm - @grid-gutter-width);
                    }
                    @media (min-width: @screen-md-min) {
                        width: (@container-md - @grid-gutter-width);
                    }
                    @media (min-width: @screen-lg-min) {
                        width: (@container-lg - @grid-gutter-width);
                    }
                }
            }

            .new {
                text-transform: uppercase;
                color: @brand-turquoise;
                font-weight: 500;
                font-sizze: 17px;
            }

            h3 {
                a {
                    color: @brand-grey;

                    &:hover {
                        color: @brand-red;
                    }
                }

                padding-bottom: 10px;
                margin-top: 5px;
            }

            ul.links {
                margin-top: 5px;
                float: right;

                a {
                    color: @brand-turquoise;

                    &:hover {
                        color: @brand-red;
                    }
                }
            }
        }
    }

    /*
        Block: user_flagged_node
     */

    #block-views-559bf4621fbec0b1f12f7666661d62f3 {
        h2.block-title {
            border-bottom: 1px solid @brand-red;
            font-weight: 300;
            font-size: 24px;
            margin-top: 30px;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }

        .view-user-flagged-nodes {
            .views-row {
                &:not(:last-child) {
                    & span:after {
                        content: ",";
                        margin-right: 6px;
                        display: inline-block;
                    }
                }

                float: left;
            }
        }
    }

    .views-exposed-form {
        justify-content: left;
        border: none;
        padding-bottom: 0px;
        margin-bottom: 20px;
    }
    .field-name-body .views-exposed-form {
        margin-bottom: 0px;
    }

    .review-box {
        .link_create_review {
            float: left;
            margin-right: 15px;
        }

        .link_show_reviews {
            float: right;
        }

        .fivestar-summary {
            display: none;
        }

        .field-content {
            float: right;
        }

        .views-field {
            margin: 15px 0px;
        }
    }
}
.views-field.views-field-view-node {
    margin-top: 10px;
}

.group-page.bandprofil {
    .row-sharing {
        margin-bottom: 10px;
    }
    .group-row-bandmembersearch {
        .bandmembersearch {
            border-bottom : 1px solid @brand-red;
            margin-bottom : 40px;
        }
        .field-collection-item-field-fc-bandmitgliedersuche {
            padding : 20px 15px 35px 15px;
        }
        .field-name-field-searchtitle {
            font-size : 35px;
            color     : @brand-turquoise;
        }
        .field-name-field-fc-instrument {
            padding-left : 15px;
            .field-label {
                font-size   : 20px;
                font-weight : normal;
            }
            .field-item {
                font-size : 20px;
                color     : @brand-turquoise;
            }
        }
    }

    .group-row-image_gallery .field_bildergalerie {
        border-top : 1px solid @brand-red;
        margin-top : 20px;
        padding-top: 30px;
        padding-bottom: 30px;
        @media (min-width: @screen-md) {
            /* the slides */
            .slick-slide {
                margin : 0 24px;
            }
            /* the parent */
            .slick-list {
                margin : 0 -24px;
            }
        }
        @media (min-width: @screen-lg) {
            /* the slides */
            .slick-slide {
                margin : 0 80px;
            }
            /* the parent */
            .slick-list {
                margin : 0 -80px;
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
    .group-row-videos .container,
    .group-row-soundcloud .container {
        margin-top: 20px;
        margin-bottom: 60px;
        position: relative;
        .row .col .title {
            border-top : 1px solid @brand-red;
            margin-top : 10px;
            color: @brand-red;
            font-size: 28px;
            padding: 20px 0px;
        }
        img {
            width: 100%;
        }
    }
    .field_fc_bandmitglieder .field-name-field-fc-bandmitglieder .field-items .field-item {
        a {
            color: @brand-turquoise;
            &:hover {
                color: @brand-red;
            }
        }
        .field-name-field-bandmitglied.field-type-entityreference {
            float : left;
            /* only if reference and text is setted a separator is needed*/
            & + .field-name-field-bandmitgliedsname.field-type-text {
                .field-item:before {
                    content : " aka ";
                    margin  : 0px 6px;
                    display : inline-block;
                    color   : @brand-dark-grey;
                    font-weight: 100;
                }
            }
        }
        .field-name-field-bandmitgliedsname.field-type-text {
            float : left;
            .field-item {
                color : @brand-red;
            }
        }

        .field-name-field-fc-instrument.field-type-field-collection {
            .field-items {
                float:left;
            }
            &:before {
                content : " - ";
                margin: 0px 6px;
                display : inline-block;
                float:left;
            }
        }
    }
}


/* Taxonomy */
.page-taxonomy-term {
    .row-type {
        display: none;
    }
    h1.page-header {
        font-size: 40px;
        @media (min-width: @screen-xs) {
            font-size : 50px;
        }
        @media (min-width: @screen-sm) {
            font-size : 65px;
        }

    }
    h1 {
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 25px;
        text-align: left;
        font-weight: 500;
    }
    .col {
        width: 100%;
    }
    .linklist .col {
        width: inherit;
    }
    .line-divider {
        display: none;
    }
    article {
        &:not(:last-child) {
            border-bottom : 1px solid @brand-red;
        }
        &:last-child {
            margin-bottom: 80px;
        }
        margin: 15px 0px 15px 0px;
        padding-bottom: 20px;
        position:relative;
        @media (min-width: @screen-sm) {
            .row-main-content .row {
                width: 60%;
            }
            &.node-event-calendar.node-teaser {
        	.main-content,
        	.contact-box {
        	    width: 60%;
        	    padding-right: 15px;
        	}
            }
            .field-name-field-mainimage,
            .field-name-field-bildergalerie {
                width: 40%;
                position: absolute;
                right: 0px;
                top: 0px;
                /*bottom: 50px;*/
            }
			.field-name-field-bildergalerie {
				padding-right: 15px;				
			}
			&.node-event-calendar .node-readmore {
			    padding-left: 20px;
				padding-top: 40px;
			}
        }
    }
    .taxonomy-term.vocabulary-tags > .content {
        &:extend(.container all);

        border-bottom: 1px solid @brand-red;
        padding-bottom:20px;
    }
    .comment_forbidden {
        display: none;
    }
}

.front header#navbar {
    display: none;
}

header#navbar.navbar.container.inImageTeaser {
    position: absolute;
    /*background-color: transparent;*/
    background-color: rgba(129, 17, 65, .75);
    @media (min-width: @screen-md) {
        background-color: transparent;
    }
    display: block;

    & > .container {
        max-width: 100%;
        @media (min-width: @screen-lg-min) {
            ul {
                font-size: 15px;
                margin-left: 5px;

                li {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
        @media (min-width: @screen-md-min) {
            /*ul {
                font-size: 14px;
                margin-left: 5px;

                li {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }*/
        }
    }

    .navbar-collapse nav > ul > li {
        /*background-color: rgba(0, 0, 0, 0.35);*/
        background-color: transparent;
        /*@media (min-width: @screen-sm-min) {
            background-color: rgba(0, 0, 0, 0.25);
        }*/
        @media (min-width: @screen-md-min) {
            background-color: rgba(0, 0, 0, .25);
        }
    }

    .navbar-collapse {
        @media (max-width: @screen-md) {
            margin-top: -35px;
            margin-right: 0px;
        }
        @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
            margin-top: -40px;
        }
    }

    /* coloring navbar in ImageTeaser */

    ul.navbar-nav > li {
        > a {
            color: @white;
            background-color: transparent;
        }

        > a:hover,
        &.open > a {
            /*background-color: @brand-red;*/
            @media (min-width: @screen-md) {
                background-color: @brand-red;
            }
        }

        &.open {
            .dropdown-menu {
                /*background-color: @brand-red;*/
                background-color: transparent;
                @media (min-width: @screen-md) {
                    background-color: @brand-red;
                }
                a {
                    color: @white;

                    &:hover {
                        color: @brand-turquoise;
                    }
                }
            }
        }
    }

    /* change logo in ImageTeaser */
    .navbar-header .logo img {
        content: url(@logo_kompakt_neg);
        max-width: 265px;
        @media (min-width: @screen-sm-min) {
            max-width: 300px;
            width: 300px;
            margin-top: -15px;
            margin-left: 20px;
        }
        @media (min-width: @screen-md) {
            content: url(@logo_kompakt_black);
        }
    }
}

header#navbar.navbar.container.fixedHeader {
    position: fixed;
    min-height: 67px;
    padding-top: 0px;
    /*transition: all @headerTransitionSpeed ease;*/
    display: block;

    box-shadow: 1px 1px #552222;

    ul.navbar-nav {
        padding-top: 0px;
        /*transition: all @headerTransitionSpeed ease;*/
    }

    .navbar-header {
        max-width: 340px;
        margin-top: -10px;

        .logged-in & {
            max-width: 300px;
        }

        /*transition: all @headerTransitionSpeed ease;*/

        @media (max-width: @screen-md-min) {
            .logo {
                margin-top: 20px;
            }

            .navbar-toggle {
                margin-top: 20px;
            }
        }
    }

    > .container {
        transoform: inherit;
    }
}


/* full width home teaser */
#block-views-teaser-articles-image-teaser,
#block-views-fa2cf4404d431972e57af74309f74c94 {
    /* zoom effect main teaser */
    /*.carousel-inner .item img {
        -webkit-animation: thing 20s;
        -o-animation: thing 20s;
        animation: thing 20s;
    }
    @keyframes thing {
        from {
            transform: scale(1, 1);
        }
        to {
            transform: scale(1.5);
        }
    }*/
    .item img.img-responsive {
        max-width: inherit;
        width: 100%;
    }

    .carousel-control,
    .carousel-indicators {
        display: none;
    }

    /*****
        try for triangle image
        &:after {
         width             : 100px;
         height            : 100px;
         margin-left       : 550px;
         background        : url(http://placekitten.com/g/1000/200) -550px 0;
         -webkit-clip-path : polygon(0 100%, 50% 0, 100% 100%);
         clip-path         : polygon(0% 100%, 50% 0, 100% 100%);

         background: #000000;
         content:" ";
         display:block;
     }*****/

    /* bottom caret */
    .item  {
        &:before {
            right: 50%;
            -ms-transform-origin: 100% 100%;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -ms-transform: skewX(45deg);
            -webkit-transform: skewX(45deg);
            transform: skewX(45deg);
        }
        &:after {
            left: 50%;
            -ms-transform-origin: 0 100%;
            -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
            -ms-transform: skewX(-45deg);
            -webkit-transform: skewX(-45deg);
            transform: skewX(-45deg);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 50%;
            background-color: #ffffff;
            padding-bottom: 4%;
            margin-bottom: -1px;
        }
    }

    .carousel-caption {
        background-color: rgba(0, 0, 0, 0.2);
        padding: 25px 40px;
        text-align: left;

        top: 65px;
        max-width: 100%;

        left: 0px;
        right: 0px;
        bottom: inherit;

        h1, h2 {
            font-size: 25px;
            margin-bottom: 5px;
            font-weight: 500;
            text-align: left;
            margin-top: 0px;
            text-transform: inherit;
            word-wrap: break-word;
        }

        p {
            font-size: 15px;
            word-wrap: break-word;
        }

        @media (min-width: @screen-xs) {
            top: 85px;
            max-width: 80%;
            left: 30px;
            right: inherit;
            h1, h2 {
                font-size: 25px;
                margin-bottom: 5px;
                word-wrap: inherit;
            }

            p {
                word-wrap: inherit;
            }
        }
        @media (min-width: @screen-sm-min) {
            top: 100px;
            bottom: inherit;
            max-width: 80%;
            h1, h2 {
                font-size: 35px;
                margin-bottom: 5px;
                font-weight: 100;
                word-wrap: inherit;
            }

            p {
                font-size: 20px;
                word-wrap: inherit;
            }

            a.more-information {
                font-size: 15px;
            }
        }
        @media (min-width: @screen-md-min) {
            top: 150px;
            max-width: 60%;
            h1, h2 {
                font-size: 40px;
                margin-bottom: 5px;
            }

            p {
                font-size: 25px;
            }

            a.more-information {
                font-size: 18px;
            }
        }
        @media (min-width: @screen-lg-min) {
            top: 200px;
            max-width: 70%;
            h1, h2 {
                font-size: 50px;
                margin-bottom: 10px;
            }

            p {
                font-size: 30px;
            }

            a.more-information {
                font-size: 18px;
            }
        }
        @media (min-width: @screen-xl-min) {
            top: 250px;
            h1, h2 {
                font-size: 70px;
            }
        }

        p {
            font-weight: 500;
        }

        a {
            color: @white;
        }

        a.more-information {
            margin-top: 15px;
            margin-bottom: 20px;
            background-color: @brand-red;
            border-color: @brand-dark-grey;
            text-transform: uppercase;
            font-weight: 100;

            &:hover {
                background-color: @brand-turquoise;
                border-color: @brand-dark-grey;
            }
        }
    }

    /* special for mobile hometeaser */
    @media (max-width: @screen-xs) {
        .item.extraMobileImage .carousel-caption {
            top: 190px;
        }

        .item:not(.extraMobileImage) .carousel-caption {
            bottom: 7%;

            a.more-information {
                display: none;
            }
        }
    }


}

/* parallax suche */
#block-quicktabs-home-qtabs,
#block-quicktabs-suche-nach-musikunterricht {
    margin-top: 50px;
    margin-bottom: 50px;

    /* The image used */
    background-image: url("../images/parallax/parallax-section-bg-bandteaser.jpg");

    /* Set a specific height */
    height: 820px;
    @media (min-width: @screen-xs) {
        height: 790px;
    }
    @media (min-width: @screen-sm) {
        height: 850px;
    }
    @media (min-width: @screen-md) {
        height: 720px;
    }

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .text-block-container {
        h2 {
            font-size: 40px;
            font-weight: 100;
            text-transform: uppercase;
            word-wrap: break-word;

            @media (max-width: @screen-xs-max) {
                letter-spacing: -5px;
            }
            @media (min-width: @screen-sm-min) AND (max-width: @screen-sm-max) {
                letter-spacing: -3px;
            }
        }

        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
        /*padding: 0px 40px;*/
        color: @brand-dark-grey;
        font-size: 15px;
        word-wrap: break-word;

        @media (min-width: @screen-xs) {
            h2 {
                font-size: 50px;
                word-wrap: inherit;
            }

            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 20px;
            word-wrap: inherit;
        }
        @media (min-width: @screen-sm) {
            h2 {
                font-size: 65px;
            }

            margin-top: 70px;
            margin-bottom: 60px;
            font-size: 25px;
        }


        &:extend(.container all);
    }

    > .block-container {
        &:extend(.container all);
    }

    #quicktabs-search,
    #quicktabs-suche_nach_musikunterricht {
        .tab-content {
            background-color: @brand-red;
        }

        .nav {
            border: none;
            display: flex;
            justify-content: center;
        }

        .nav-tabs {
            margin-bottom: 0px;

            li {
                margin-bottom: -2px;

                a {
                    color: @brand-light-grey;
                    margin-right: 0px;
                    /*background-color: @brand-middle-grey;*/
                    background-color: @brand-red;
                    border: none;
                }

                border: 5px solid transparent;

                &.active {
                    border: 5px solid @brand-red;
                    background-color: @brand-red;
                }

                &:hover a:hover,
                &.active a {
                    color: @white;
                    /*background-color: @brand-red;*/
                }
            }
        }

        #quicktabs-search-mobile-select {
            display: block;
            margin: 15px auto;
            background-color: @brand-red;
            color: @white;
            text-transform: uppercase;
            font-size: 18px;
            padding: 7px;
        }

        /* tab / selectbox switch for mobile / desktop */
        .nav.nav-tabs {
            display: none;
        }

        @media (min-width: @screen-xs) {
            #quicktabs-search-mobile-select {
                display: none;
            }

            .nav.nav-tabs {
                display: flex;
            }
        }
    }

    .nav.nav-pills {
        display: none;
    }

    .views-exposed-form {
        margin-bottom: 0px;
        padding-top: 30px;
        border-bottom: none;
    }
    #views-exposed-form-search-page .views-exposed-form .views-exposed-widgets {
        width: 90%;
        @media (min-width: @screen-md) {
            #edit-term-wrapper.views-exposed-widget {
                width: 50%;
            }
            #edit-location-wrapper.views-exposed-widget {
                width: 49%;
            }
        }
    }

    @media (min-width: @screen-xs) {
        #block-views-exp-gruppensuche-bandsearch .views-exposed-widget:not(.views-submit-button) {
            width: 50%;
        }
    }

    .views-exposed-widget.views-submit-button {
        width: 100%;
        text-align: center;
    }

    /* button position fixes */

    #edit-submit-gruppensuche.btn {
        margin-top: 14px;
    }

    #edit-submit-proberaum.btn {
        margin-top: 7px;
    }
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
/*@media only screen and (max-device-width: 1366px) {
  #outer-footer-section .footer-section .section-divider-footer {
    background-attachment: scroll;
  }
}*/

.front {
    #block-views-teaser-articles-newsteaser-bom,
    #block-system-main {
        .block-container {
            &:extend(.container all);
        }
    }
}

.itemMobile {
    display: none;
}


/* hometeaser articles (text articles) */
.view-teaser-articles.view-display-id-page,
.view-teaser-articles.view-display-id-page_musikunterricht,
.view-teaser-landing-pages.view-display-id-home_bom,
.view-teaser-landing-pages.view-display-id-home_mu {
    .intro-text {
        text-align: center;
        h3 {
            margin-top: 0px;
            font-size: 40px;
            text-transform: uppercase;
            font-weight:500;
            word-wrap: break-word;
            @media (min-width: @screen-xs) {
                font-size : 50px;
                word-wrap: inherit;
                font-weight:100;
            }
            @media (min-width: @screen-sm) {
                font-size: 50px;
            }

            @media (max-width: @screen-xs-max) {
                letter-spacing: -5px;
            }
            @media (min-width: @screen-sm-min) AND (max-width: @screen-sm-max) {
                letter-spacing: -3px;
            }
        }
        p {
            font-size: 15px;
            word-wrap:break-word;
            font-weight:500;
            @media (min-width: @screen-xs) {
                font-size : 20px;
                font-weight:100;
                word-wrap: inherit;
            }
            @media (min-width: @screen-sm) {
                font-size: 25px;
            }
        }
    }
    .row {
        margin-bottom: 50px;
        margin-left: 0px;
        margin-right: 0px;

        @media (min-width: @screen-xs) {
            margin-left:-15px;
            margin-right: -15px;

            margin-top: 40px;
            margin-bottom: 80px;

            display: flex;
        }

    }
    .col {
        .field-content > p {
            font-weight: normal !important;
            a {
                color: @brand-turquoise;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        background-color: @brand-red;
        /*@media (max-width: @screen-sm) {*/
        padding-bottom: 20px;
        /*}*/
        margin-bottom: 60px;
        @media (min-width: @screen-xs) {
            margin-bottom: 0px;

            &:first-child {
                margin-right: 10px;
                margin-left: 20px;
            }

            &:nth-child(2) {
                margin-left: 10px;
                margin-right: 10px;
            }

            &:nth-child(3) {
                margin-left: 10px;
                margin-right: 20px;
            }
        }
    }
    h3 a,
    .views-field-body {
        color: @white;
    }
}
.views-field-field-teaser-icon {
    .field-content {
        text-align: right;
        height: 70px;
        @media (min-width: @screen-sm) {
            height: 110px;
        }
    }

    .teaser_icon {
        background-size: cover;
        width: 105px;
        height: 100px;
        @media (min-width: @screen-sm) {
            width: 140px;
            height: 133px;
        }
        display: inline-block;
        /*background-color: @brand-light-grey;*/

        &.none {
            background-color: transparent;
        }

        &.book {
            background-image: url('../images/teaser_icons/sprechblase-book.svg');
            /*-webkit-mask-image: url('../images/teaser_icons/sprechblase-book.svg');
            -webkit-mask-size: cover;
            mask-image: url('../images/teaser_icons/sprechblase-book.svg');
            mask-size: cover;*/
        }

        &.bubble {
            background-image: url('../images/teaser_icons/sprechblase.svg');
            /*-webkit-mask-image: url('../images/teaser_icons/sprechblase.svg');
            -webkit-mask-size: cover;
            mask-image: url('../images/teaser_icons/sprechblase.svg');
            mask-size: cover;*/
        }

        &.wave {
            background-image: url('../images/teaser_icons/tonspur.svg');
            /*-webkit-mask-image: url('../images/teaser_icons/tonspur.svg');
            -webkit-mask-size: cover;
            mask-image: url('../images/teaser_icons/tonspur.svg');
            mask-size: cover;*/
            width: 115px;
            @media (min-width: @screen-sm) {
                width: 150px;
            }
        }

        &.tone {
            background-image: url('../images/teaser_icons/note.svg');
            /*-webkit-mask-image: url('../images/teaser_icons/note.svg');
            -webkit-mask-size: cover;
            mask-image: url('../images/teaser_icons/note.svg');
            mask-size: cover;*/
            width: 90px;
            @media (min-width: @screen-sm) {
                width: 120px;
            }
        }
    }
}

/* teaser articles */
.view-id-teaser_articles.view-display-id-newsteaser_bom,
.view-id-teaser_articles.view-display-id-newsteaser_musikunterricht {
    td {
        border-top: none;
    }

    @media (max-width: @screen-sm) {
        tr:not(:last-child) td:last-child {
            border-bottom: 1px solid @brand-red;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
    }
    @media (min-width: @screen-sm) {
        tr:not(:last-child) td {
            border-bottom: 1px solid @brand-red;
            padding-bottom: 20px;
        }
        tr:not(:first-child) td {
            padding-top: 20px;
        }
    }


    td.views-field-field-image {
        width: 100%;
        display: block;
        @media (min-width: @screen-sm) {
            display: table-cell;
            width: 40%;
        }
        img {
            max-widht: inherit;
            width: 100%;
        }
    }
    td.views-field-body {
        width: 100%;
        display: block;
        @media (min-width: @screen-sm) {
            display: table-cell;
            width: 60%;
        }
        h3 {
            margin-top: 0px;
        }
    }
}
.views-field-field-additional-links {
    background: @brand-dark-grey;
    width: 100%;
    display: flex;
    justify-content: space-around;

    position: absolute;
    bottom: -53px;
    left: 0px;
    .field-content {
        text-align: center;
        display: flex;
        width: 100%;
    }
    a {
        display: flex;
        justify-content: space-around;
        width: 100%;
        color: @white;
        padding: 15px 0px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 100;
        &:hover {
            color: @brand-turquoise;
        }

        &:nth-child(2) {
            border-left: 1px solid @white;
            /*display:none;*/
        }
    }
}

/* article front slider */
.front_article_slide_bom,
.front_article_slide_mu {
    .block-title {
        font-size: 40px;
        font-weight: 100;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 30px;
        padding-top: 50px;
        word-wrap: break-word;
        @media (min-width: @screen-xs) {
            font-size : 50px;
            word-wrap: inherit;
        }
        @media (min-width: @screen-sm) {
            font-size : 65px;
        }
    }
    .block-container {
        background: @brand-light-grey;
        padding-bottom: 80px;
    }
    .slick--skin--boxed > .slick__slider > .slick-list,
    .slick--skin--boxed-carousel .slick-list {
        /* 1er */
        margin: 0px 5px;

        @media (min-width: @screen-xs) {
            /* 2er */
            margin: 0px 5px;
        }
        @media (min-width: @screen-sm) {
            /* 3er */
            margin: 0px 15px;
        }
    }
    .view-display-id-bom_article_slider,
    .view-display-id-mu_article_slider {
        margin: 0px auto;

        @media (min-width: @screen-sm) {
            width: @screen-sm;
        }
        @media (min-width: @screen-md) {
            width: @screen-md;
        }
        @media (min-width: @screen-lg) {
            width: @screen-lg;
        }
    }
    .slick--skin--boxed-carousel .slide__content {
        background:@brand-dark-grey;
        color: @white;

        padding-bottom: 30px;
        box-shadow: 6px -3px 10px 0px @brand-middle-grey;
        border: 0px;
        div.views-field:not(.views-field-field-image) {
            padding: 0px 10px;
        }
        h3 {
            overflow-wrap: break-word;
            a {
                color       : @white;
                font-weight : 900;
                font-size   : 20px;
                &:hover {
                    color : @brand-turquoise;
                }
            }
        }
        .views-field-view-node a {
            font-weight: 900;
            color: @brand-turquoise;
            position: absolute;
            right: 15px;
            bottom: 9px;

            &:hover {
                color: @white;
            }
        }
    }
}

/* review music teaching node */
.page-node.node-type-unterrichtsbewertung,
.page-node.node-type-proberaumbewertung,
.page-node.node-type-musikerbewertung {
    h1 {
        text-align: left;
    }
    .field-name-title-field {
        display: none;
    }
    .field-name-field-bewertung-fuer,
    .field-name-field-bewertung-fuer-proberaum,
    .field-name-field-bewertung-fuer-musiker {
        margin-top: 100px;
        font-size: 20px;
    }
}
.page-node.node-type-unterrichtsbewertung,
.page-node.node-type-proberaumbewertung,
.page-node.node-type-musikerbewertung,
.page-unterrichtsbewertungen,
.page-proberaumbewertungen,
.page-musikerbewertungen {
    .breadcrumb {
        display: none;
    }
}
/* review music teaching overview page */
.view-id-unterrichtsbewertung.view-display-id-page,
.view-id-proberaumbewertungen.view-display-id-page,
.view-id-musikerbewertungen.view-display-id-page {
    .user-picture {
        position: absolute;
        margin-top: 70px;
        margin-left: -15px;
    }
    .submitted {
        margin-left: 85px;
    }
    .field-name-title-field {
        display: none;
    }
    .field-name-field-bewertung-fuer,
    .field-name-field-bewertung-fuer-proberaum,
    .field-name-field-bewertung-fuer-musiker {
        margin-top: 100px;
        font-size: 20px;
    }
    .views-row {
        margin-bottom: 25px;
        padding-bottom: 25px;
        &:not(:last-child) {
            border-bottom: 1px solid #000000;
        }
    }
}

.node-type-unterrichtsbewertung,
.node-type-proberaumbewertung,
.node-type-musikerbewertung,
.view-unterrichtsbewertung,
.view-proberaumbewertungen,
.view-musikerbewertungen {
    .field-name-title-field {
        font-size: 16px;
        font-weight: bold;
        padding-top: 30px;
        clear: both;
    }

    .field-name-field-bewertung-fuer,
    .field-name-field-bewertung-fuer-proberaum,
    .field-name-field-bewertung-fuer-musiker {
        margin: 5px 0px 15px 0px;
        .field-label {
            font-weight: normal;
            margin-right: 10px;
        }
    }

    .field-name-body {
        margin-bottom: 30px;
    }

    .field-type-fivestar {
        float: left;
        margin: 15px 40px 15px 0px;
        .fivestar-summary-average-count {
            display: none;
        }
    }


    .field-type-fivestar.field-name-field-average {
        float: none;
        clear: both;
        .fivestar-summary-average-count {
            display: block;
            .total-votes {
                display: none;
            }
        }
    }


    #block-shariff-shariff-block {
        display: none;
    }

    .user-picture {
        max-width: 100px;
        float: left;
    }
}

.node-type-anzeige-proberaum,
.node-type-musician-profile,
.node-type-proberaumbewertung,
.node-type-musikerbewertung,
.view-proberaumbewertungen,
.view-musikerbewertungen {
    .field-name-field-cat-1,
    .views-field-field-cat-1,
    .views-field-field-cat-1-1 {
        display:none;
    }
}


/************************************/
/***       style fixes nsh        ***/
/************************************/

header#navbar.navbar.container.navbar-default.inImageTeaser {
    .navbar-header button {
        margin-top: 15px;
    }
    @media (max-width: @screen-sm-max) {
        .navbar-collapse nav > div > section > div > ul > li {
            border-bottom: 1px solid rgba(255, 255, 255, .5);
            &:last-child {
                border: none;
            }
        }
        .expanded.dropdown.open {
            .dropdown-toggle {
                background-color: rgba(255, 255, 255, .2);
            }
        }
    }
}

#edit-field-favorite-music-genres-und {
    display: flex;
    flex-wrap: wrap;
    .form-item {
        @media screen and (max-width: @screen-xs) {
            width: 100%;
        }
        @media screen and (min-width: @screen-xs) {
            width: 50%;
        }
        @media screen and (min-width: @screen-sm) {
            width: 25%;
        }
    }
}

.slick-track .slick__slide.slide.slide--loading {
    display: none;
}

.node-musician-profile {
    .field-collection-item-field-fc-contact {
        .field-item {
            a {
                text-decoration: underline dotted #fff;
            }
        }
    }
}

.linklist {
    .link {
        &:before {
            content: "";
            position: absolute;
            height: 20px;
            width: 10px;
            background-size: 10px;
            background-position: 0 5px;
            background-image: url('../images/arrow-right.svg');
            background-repeat: no-repeat;
        }
        a {
            padding-left: 15px;
            display: block;
        }
    }
}

.view-my-nodes {
    .view-content {
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 14px;
            height: 14px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 3px solid #fff;
            background-color: rgba(0, 0, 0, .3);
        }
    }
}

.comment-wrapper {
    .comment {
        h3 {
            margin: 0;
        }
        .content {
            margin-bottom: 20px;
        }
        .submitted {
            font-size: 13px;
            margin-bottom: 0;
        }
    }
}

.view.view-groups{
    overflow: auto;
}

.view-empty {
    margin-bottom: 15px;
}

button.btn-single {
    display: block;
    margin: 25px auto;
    max-width: 400px;
}

.block-container.container {
    max-width: 100%;
    @media (max-width: @screen-md-max) {
        padding: 0;
    }
}

header#navbar.navbar.container.navbar-default.inImageTeaser {
    .navbar-header button {
        margin-top: 15px;
    }
    @media (max-width: @screen-sm-max) {
        .navbar-collapse nav > ul > li {
            border-bottom: 1px solid rgba(255, 255, 255, .5);
            &:last-child {
                border: none;
            }
        }
        .expanded.dropdown.open {
            .dropdown-toggle {
                background-color: rgba(255, 255, 255, .2);
            }
        }
    }
}

.main-container .region {
    @media (max-width: @screen-sm) {
        #block-views-exp-musician-musician-profile form {
            .views-widget-filter-field_fc_address_field_geofield_latlon {
                width: 100%;
            }
            .views-submit-button {
                width: 100%;
                text-align: center;
                button {
                    width: 100%;
                    margin: 10px auto;
                }
            }
        }
        .view-header a {
            width: 100%;
        }
    }
}

header#navbar.navbar section#block-user-login {
    @media (max-width: @screen-xs-max) {
        left: 0;
        right: 0;
    }
    form {
        .form-links {
            position: relative;
            padding-top: 10px;
            a[href="/user/password"] {
                position: absolute;
                top: -10px;
                right: 0;
                font-size: 14px;
            }
        }
        .form-submit {
            margin: 5px 0;
        }
        hr {
            margin: 10px 0;
        }
        .btn-register {
            display: block;
            margin: 5px 0;
            background-color: #009290;
            border-color: #009290;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.main-container.container .row {
    .alert.alert-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    }
}

/* CERTIFICATES */
.field_fc_certificates {
    margin-top: 20px;
    margin-bottom: 20px;
    h4 {
        margin: 0;
    }
}

.view-search,
.view-music-teaching {
    .thumbnail {
        &.certificated-profile {
            box-shadow: 0 0 0 4px @brand-turquoise;
        }
        position: relative;
        .caption {
            .certificate-link {
                position: absolute;
                top: 100px;
                right: 5px;
                .certificate-seal {
                    width: 60px;
                }
            }
        }
    }
}

.profile {
    .user-picture {
        img {
            margin: 0 auto;
        }
    }
}

.my-profiles,.my-groups,.my-content,.my-adverts {
    margin-bottom: 40px;
    h2,h3,h4 {
        text-align: center;
    }
    .my-profiles-section,.my-groups-section,.my-content-section,.my-adverts-section {
        padding: 5px;
        margin-bottom: 10px;
        .my-profile,.my-group,.my-advert {
            @media (max-width: @screen-md) {
                margin-bottom: 100px;
            }
        }
        h3 {
            color: white;
            background-color: @brand-red;
            padding: 5px;
        }
        .my-profiles-panel,.my-groups-panel {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            background-color: #fafafa;
            background-clip: content-box;
            margin-bottom: 20px;
            min-height: 250px;
            a {
                text-align: center;
                display: block;
                flex-basis: 100%;
                z-index: 1;
                color: #ffffff;
                padding: 5px;
                font-size: 18px;
                text-shadow: 3px 3px 2px #000000;
                border: 1px solid #000000;
                transition: background-color 0.25s ease-in-out;
                background-color: rgba(0, 0, 0, .4);
                &:hover {
                    background-color: rgba(255, 255, 255, .3);
                }
            }
            &:hover {
                .background-image-container {
                    transform: scale(1.4);
                }
            }
            .my-profile-likes,.my-group-likes {
                position: absolute;
                bottom: 10px;
                right: 10px;
                z-index: 1;
                color: #ffffff;
                padding: 4px 4px 4px 30px;
                background-color: rgba(0, 0, 0, .4);
                &:before {
                    content: " ";
                    position: absolute;
                    left: 3px;
                    bottom: 3px;
                    background-image: url(../../../../default/files/like.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 20px;
                    height: 24px;
                    width: 24px;
                }
            }
            .background-image-container {
                position: absolute;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                transition: transform 0.25s ease-in-out;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                &.default-screen {
                    opacity: .5;
                }
            }
            .group-type-label {
                position: absolute;
                top: 20px;
                left: 0;
                padding: 5px 10px;
                opacity: .8;
                &.public-group {
                    color: white;
                    background-color: @brand-turquoise;
                }
                &.private-group {
                    color: white;
                    background-color: @brand-grey;
                }
            }
            /* default images for 'add new content/group' */
            &.profile-musician {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/myprofile-musician.png);
                }
            }
            &.profile-teacher {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/myprofile-teacher.png);
                }
            }
            &.profile-school {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/myprofile-school.png);
                }
            }
            &.profile-rehearsal {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/myprofile-rehearsal.png);
                }
            }
            &.group-general-public {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/mygroup-general-public.png);
                }
            }
            &.group-general-private {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/mygroup-general-private.png);
                }
            }
            &.group-band {
                .default-screen {
                    background-image: url(/sites/default/files/images/default/mygroup-band.png);
                }
            }
        }
        .my-content-entry {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid @brand-middle-grey;
            &.content-article {
                .my-content-link {
                    display: block;
                    font-size: 24px;
                }
                .post-date {
                    position: relative;
                    margin-top: 15px;
                    padding-left: 23px;
                    font-weight: bold;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        left: 0px;
                        bottom: 3px;
                        background-image: url('../images/layout/calendar.png');
                    }
                }
            }
        }
        .my-adverts-entry {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid @brand-middle-grey;
            &.advert-marketplace {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            &.advert-job,&.advert-marketplace {
                .my-advert-link {
                    font-size: 24px;
                }
                .advert-image {
                    height: 135px;
                    background-size: cover;
                    background-position: center;
                }
                .advert-text {
                    .headline-categories {
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                }
                .advert-price {
                    font-size: 20px;
                    text-align: left;
                    @media(min-width: @screen-md-min) {
                        text-align: center;
                    }
                }
                .advert-created {
                    text-align: right;
                    @media(min-width: @screen-md-min) {
                        text-align: center;
                    }
                }
                .location {
                    position: relative;
                    padding-left: 19px;
                    font-weight: bold;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 11px;
                        height: 14px;
                        left: 0px;
                        bottom: 4px;
                        background-image: url('../images/layout/map_marker.png');
                    }
                }
            }
        }
    }
}

#views-exposed-form-search-page,
#views-exposed-form-rehearsal-room-page { 
    .views-exposed-form{
        @media (min-width: @screen-md) {
            .views-exposed-widgets {
                width: 90%;
                #edit-term-wrapper {
                    width: 50%;
                }
                #edit-location-wrapper {
                    width: 35%;
                }
            }
        }
        @media (max-width: @screen-sm-max) {
            .views-exposed-widgets {
                width: 80%;
                .views-exposed-widget {
                    float: none;
                    #edit-term-wrapper,
                    #edit-location-wrapper {
                        width: 80%;
                    }
                    input {
                        margin-bottom: 10px;
                    }
                }
                #edit-field-favorite-music-genres-name-wrapper,
                #edit-field-fc-instrument-field-instrument-name-wrapper {
                    display: none;
                }
                .views-submit-button {
                    width: 100%;
                    button {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        @media (max-width: @screen-xs) {
            .views-exposed-widgets {
                width: 100%;
            }
        }
    }
}

.search-filter-by-type {
    display: flex;
    flex-wrap: wrap;
    .content-type-filter,.group-type-filter {
        padding: 4px 8px;
        border: 1px solid @brand-red;
        margin-right: 10px;
        margin-bottom: 8px;
        cursor: pointer;
        &.active-filter {
            background-color: @brand-red;
            color: #ffffff;
        }
    }
}

form[action="/search"] .views-exposed-form {
    margin-bottom: 30px;
}



/* new message badge */
a:hover.link-badge-wrapper .link-badge-menu_badges_privatemsg_unread_count {
    border: 2px solid #f5f5f5;
}

span.link-badge-text{
    margin-right: 8px;
}

.link-badge.link-badge-menu_badges_privatemsg_unread_count{
    background: @brand-red;
    color:@brand-light-grey;
    margin-top: 2px;
    margin-right: 5px;
    border:2px solid rgba(0, 0, 0, 0.1);
}

/* faq*/
.view-faq {
    border: 1px solid @brand-red;
    border-top: none;
    .faq-category {
        margin-bottom: 0px;
        font-size: 25px;
        background: @brand-red;
        color: @brand-light-grey;
        padding: 5px 10px ;
    }
    .view-content {
        .views-row {
            margin: 20px 10px;
            border: 1px solid @brand-light-grey;
            .faq-question{
                display: flex;
                position: relative;
                font-size: 18px;
                font-weight: bold;
                padding: 5px 10px;
                cursor: pointer;
                .cc-content {
                    padding-left: 0;
                    padding-right: 0;
                }
                .cc-control {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .cc-icon {
                        height: 2.5rem;
                        transition: transform 0.25s ease-in-out;
                    }
                }
            }
            .faq-answer{
                display: none;
                font-size: 15px;
                padding: 12px;
                word-wrap: break-word;
                border-top: 1px solid @brand-light-grey;
                transform-origin: top;
            }
            .faq-options {
                text-align: right;
            }

            &.cc-closed {
                .faq-question {
                    .cc-control {
                        .cc-icon {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }
    }
}
.region-content .block-views:last-child {
    margin-bottom: 40px;
}

.information-block {
    width: 100%;
    color: white;
    background-color: @brand-red;
    padding: 5px 10px;
    margin-bottom: 20px;
    .information-title {
        font-size: 16px;
    }
    .information-text {
        font-size: 13px;
    }
}

#ui-datepicker-div {
    z-index: 10 !important;
}


/* FRONT PAGE SEARCH BLOCK */
.front-search-block {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    background-image: url('/sites/all/themes/bom/images/parallax/parallax-section-bg-bandteaser.jpg');
    background-position: center;
    background-size: cover;

    @media(max-width: @screen-sm-max) {
        min-height: 420px;
    }
    
    @media(min-width: @screen-md-min) {
        min-height: 720px;
    }

    .block-content {
        @media(max-width: @screen-md-max) {
            padding-top: 120px;
        }

        .text-container {
            text-align: center;
            margin-bottom: 20px;

            h1 {
                margin: 0 0 10px 0;
                @media (max-width: @screen-md-max) {
                    font-size: 40px;
                }
            }

            p {
                margin: 0;
                font-size: 20px;
            }
        }

        .views-exposed-form {
            border-bottom: none;

            input {
                padding: 18px;
            }

            button {
                height: 38px;
            }

            #edit-field-favorite-music-genres-name-wrapper,#edit-field-fc-instrument-field-instrument-name-wrapper {
                padding: 0;
            }
        }
    }
}

/************************************/
/*** specials für musikunterricht ***/
/************************************/
body.musikunterricht {
    header#navbar.navbar.container.inImageTeaser {
        .navbar-collapse nav > div > section > div > ul > li {
            /*background-color: rgba(0, 0, 0, 0.35);*/
            background-color: transparent;
            /*@media (min-width: @screen-sm-min) {
                background-color: rgba(0, 0, 0, 0.25);
            }*/
            @media (min-width: @screen-md-min) {
                background-color: rgba(0, 0, 0, .25);
            }
        }
    }
    &.page-user-edit {
        .field-name-field-description,
        .field-name-field-company,
        .field-name-field-home-address,
        .field-name-field-homepage,
        .field-name-field-fc-contact,
        .field-name-field-social-links,
        .field-name-field-fc-instrument,
        .field-name-field-favorite-music-genres,
        .field-name-field-favorite-music-artists,
        .field-name-field-fc-music-album-title,
        .field-name-field-fc-music-title {
            display: none;
        }
    }
}


