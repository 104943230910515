/* toDo: Teile bzgl. search aus _general verschieben */
.page-suche {
  #views-exposed-form-search-page {
    #edit-event-calendar-date-value-wrapper,
    #edit-event-calendar-date-value2-wrapper {
      display: none;
      .control-label  {
        display: none
      }
    }
    &.search-filter-event {
      @media (min-width: @screen-md) {
        #edit-location-wrapper,
        #edit-event-calendar-date-value-wrapper,
        #edit-event-calendar-date-value,
        .form-item form-item-event-calendar-date-value,
        .views-exposed-form .views-exposed-widget,
        .container-inline-date > .form-item,
        .container-inline-date .form-item input	{
          margin-right: 0px;
          padding-right:0px;

        }
        .views-exposed-widget:not(#edit-field-favorite-music-genres-name-wrapper):not(#edit-field-fc-instrument-field-instrument-name-wrapper) {
          margin-right: 10px;
        }
        #edit-term-wrapper {
          width: 35%
        }
        #edit-location-wrapper {
          width: 20%;
        }
        .views-exposed-widget.views-submit-button {
          /*clear: both;
          width: 100%;
          text-align: center;
          margin-top: 20px;*/
        }
        .views-widget-filter-event_calendar_date_value #edit-event-calendar-date-value-datepicker-popup-1,
        .views-widget-filter-event_calendar_date_value2 #edit-event-calendar-date-value2-datepicker-popup-1{
          width: 125px;
        }
      }
    }
  }
}

.front #views-exposed-form-search-page {
  #edit-event-calendar-date-value-wrapper,
  #edit-event-calendar-date-value2-wrapper {
    display: none;
  }
}
