.article-overview {
    h3,h4 {
        text-align: center;
    }
    img {
        width: 100%;
    }
    .view-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &.recent-top-articles {
        .top-article-wrapper {
            display: flex;
            flex-wrap: wrap;
            height: 300px;
            margin-bottom: 20px;
            .article-image {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;;
                height: 100%;
            }
            .article-content {
                .article-text {
                    .title {
                        font-size: 24px;
                        @media(min-width: @screen-md) {
                            border-bottom: 1px solid @brand-dark-grey;
                            padding-bottom: 5px;
                        }
                    }
                    .teaser-text {
                        margin-top: 5px;
                    }
                }
                .read-more {
                    display: block;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
                .author {
                    font-style: italic;
                }
            }
        }
    }
    &.recent-user-articles-ts {
        .article-teaser {
            width: 100%;
            margin-bottom: 30px;
            @media(max-width: @screen-sm-max) {
                padding-bottom: 20px;
                border-bottom: 1px solid @brand-dark-grey;
                margin-bottom: 20px;
            }
            .user-article-wrapper {
                display: flex;
                flex-wrap: wrap;
                @media(max-width: @screen-sm-max) {
                    &.no-image {
                        .article-image {
                            min-height: 0;
                        }
                    }
                }
                &.no-image {
                    .article-image {
                        background-size: contain;
                        background-position: top;
                    }
                }
                .article-image {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    min-height: 200px;
                }
                .article-content {
                    @media(max-width: @screen-sm-max) {
                        padding: 10px 0;
                    }
                    .article-text {
                        .title {
                            font-size: 24px;
                            @media(min-width: @screen-md) {
                                border-bottom: 1px solid @brand-dark-grey;
                                padding-bottom: 5px;
                            }
                        }
                        .teaser-text {
                            margin-top: 5px;
                            p {
                                margin: 0 0 2px;
                            }
                        }
                    }
                    .read-more {
                        display: block;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                    .author {
                        font-style: italic;
                    }
                }
            }
        }
    }
    &.recent-user-articles {
        .article-teaser {
            margin-bottom: 40px;
            & > div {
                padding: 10px;
            }
            .post-date,
            .title,
            .teaser-text,
            .author {
                color: white;
                background-color: @brand-red;
            }
            .title {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 20px;
            }
            .author {
                font-style: italic;
                a {
                    color: @brand-light-grey;
                    &:hover {
                        color: @brand-turquoise;
                    }
                }
            }
            .read-more {
                display: block;
                color: white;
                background-color: @brand-dark-grey;
                text-align: center;
                margin-top: 1px;
                text-transform: uppercase;
                padding: 10px;
                &:hover {
                    color: @brand-turquoise;
                }
            }
        }
    }
}

.node-article {
    .col.col-xs-12.col-sm-6.col-md-7.col-lg-8 {
        margin-bottom: 20px;
    }
    .article-side-infos {
        color: white;
        background-color: @brand-red;
        margin-bottom: 20px;
        .article-publishing-infos {
            display: flex;
            padding: 10px;
            color: white;
            background-color: @brand-red;
            .article-author-picture {
                width: 100px;
                height: 100px;
                background-size: cover;
                background-position: center;
                border-radius: 100px;
            }
            .article-publishing-details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 10px;
                .article-author-name {   
                    margin-bottom: 5px;
                    .article-author-name-label {
                        font-weight: bold;
                    }
                    a {
                        color: @brand-light-grey;
                        &:hover {
                            color: @brand-turquoise;
                        }
                    }
                }
                .article-publishing-date {
                    .article-publishing-date-label {
                        font-weight: bold;
                    }
                }
            } 
        }
        .article-level {
            padding: 10px;
        }
    }
}

.field_article {
    margin-bottom: 20px;
}