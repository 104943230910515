.contactBubble() {
  content:"";
  background-image: url('../images/layout/contact_bubble.png');
  /*background-image: url('../images/layout/sprechblase.svg');
  background-size: cover;*/
  width: 87px;
  height: 89px;
  display: block;
  position: absolute;
  top: 15px;
  right: 10px;
}

.sliderLogo() {
    content:"";
    background-image: url(@logo_kompakt);
    background-size: cover;
    width: 265px;
    height: 95px;
    display: block;
    position: absolute;
    top: 25px;
    left: 15px;
}