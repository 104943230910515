.node-type-event-calendar {
  .contact-box {
    margin-top: 52px;
  }
  .field_group_datum {
    .field-label h3 {
      padding: 15px 0px 0px 10px;
    }
    .duration-label {
      padding-left: 10px;
    }
    .field-name-event-calendar-date {
      padding-left: 10px;
      .date-display-start,
      .date-display-end {
        display: block;
      }
      .field-label {
        display: none;
      }
    }
  }
  .field-name-field-performing-bands,
  .field-name-field-favorite-music-genres  {
    margin-top: 25px;
    padding-left: 10px;

  }
  .copyright {
    margin-bottom: 15px;
  }
  .field-name-field-fc-contact:after {
    background-image: none;
  }
  .field-name-field-fc-contact {
    margin-top: 50px;
  }
  .field-name-field-ticket-kauf {
    margin-top: 25px;
  }
}

.page-event-created {
  #block-system-main {
    .view-calendar {
      margin-bottom: 50px;
    }
  }
}

.page-events {
  #block-views-calendar-upcomming {
    ul {
      padding: 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
    }
    li {
      flex-basis: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
      padding-right: 15px;
    }

    @media (min-width: @screen-xs-min) {
      li {
        flex-basis: 50%;
      }
    }
    @media (min-width: @screen-sm-min) {
      li {
        flex-basis: 33.33%;
      }
    }
    @media (min-width: @screen-md-min) {
    }
    @media (min-width: @screen-lg-min) {
    }
  }

  .view-calendar {
    .calendar-calendar {
      margin-bottom: 60px;
    }
  }
}
